import React, { useEffect, useState } from "react";
import Calendar from "react-calendar";
import {
  DashboardPopup,
  DisclaimerComponent,
  DisclaimerIcon,
  InformStatus,
  ReservationStatus,
  ReservationType,
  SuccessIcon,
  WhatsappIcon,
  WhatsappMessageDelivered,
  WhatsappMessageFailed,
  WhatsappMessageRead,
  WhatsappMessageSending,
  WhatsappMessageSent,
  XIcon,
} from "../components";
import useProccessReservation from "../hooks/submitProccessing";
import { InformState, ReservationDocumentType } from "../types";
import "react-calendar/dist/Calendar.css";
import { collection, doc, getDoc, onSnapshot } from "firebase/firestore";
import { db } from "../firebase";
import { useFetcher } from "react-router-dom";
import { convertUnixToDate } from "../lib/dateConvert";

export const ReservationDetails = ({
  isOpen,
  onClose,
  id,
}: {
  isOpen: boolean;
  onClose: (value: boolean) => void;
  id?: string;
}) => {
  const [data, setData] = useState<ReservationDocumentType>();
  const [calendarOpen, setCalendarOpen] = useState<boolean>(false);
  const [succesResultOpen, setSuccesResultOpen] = useState<boolean>(false);
  const [reservationDate, setReservationDate] = useState<number>();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const ProcessReservation = useProccessReservation();
  const onChange = (date: number) => {
    const unixTimestamp = Math.floor(new Date(date).getTime() / 1000);
    setReservationDate(unixTimestamp);
  };
  const DisableWeekends = ({ date, view }: { date: Date; view: string }) => {
    if (view === "month" && date.getDay() === 5) {
      return true;
    }

    return false;
  };
  useEffect(() => {
    console.log(id);
    if (id) {
      const q = doc(db, "ReservationData", id);
      const unsubscribe = onSnapshot(q, (querySnapshot) => {
        if (querySnapshot.exists()) {
          setData(querySnapshot.data() as ReservationDocumentType);
        }
      });

      return () => unsubscribe();
    } else {
      console.log("elsing");
    }
  }, [id]);

  const closeHandler = () => onClose(false);
  return (
    <DashboardPopup isOpen={isOpen} onClose={closeHandler}>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div onClick={closeHandler}>
          <XIcon />
        </div>

        <p
          className="Tx20M32"
          style={{
            flex: 1,
            margin: "0px",
            textAlign: "center",
            paddingBottom: "16px",

            flexShrink: "0",
          }}
        >
          معلومات الحجز
        </p>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          borderTop: "1px solid #DEDFE0",
          padding: "24px 0px",
        }}
      >
        <div className="info-container">
          <div className="info-cell ">
            <p className="Tx16M24 info-title">الاسم الثلاثي </p>
            <p className="Tx18M32 info-detail ">{data?.fullName}</p>
          </div>
          <div className="info-cell ">
            <p className="Tx16M24 info-title ">اللقب</p>
            <p className="Tx18M32 info-detail ">{data?.familyName}</p>
          </div>

          <div className="info-cell ">
            <p className="Tx16M24  info-title">رقم الهاتف </p>
            <p className="Tx18M32 info-detail ">{data?.phoneNumebr}</p>
          </div>
          <div className="info-cell ">
            <p className="Tx16M24  info-title">المبلغ المطلوب </p>
            <p className="Tx18M32 info-detail ">
              {data?.amount.currency}
              {data?.amount.value}
            </p>
          </div>

          <div className="info-cell ">
            <p className="Tx16M24 info-title ">نوع الطلب </p>
            <p className="Tx18M32 info-detail ">
              {data?.reservationType
                ? ReservationType(data?.reservationType)
                : ""}
            </p>
          </div>
          <div className="info-cell">
            <p className="Tx16M24 info-title ">رقم الجواز </p>
            <p className="Tx18M32 info-detail ">
              {data?.passportNumber ? data.passportNumber : "غير محدد"}
            </p>
          </div>
          <div className="info-cell">
            <p className="Tx16M24 info-title ">حالة الطلب </p>
            <p className="Tx18M32 info-detail ">
              <ReservationStatus status={data?.status} />
            </p>
          </div>
          <div className="info-cell">
            <p className="Tx16M24 info-title "> رقم الحجز </p>
            <p className="Tx18M32 info-detail ">{data?.reservationId}</p>
          </div>
          <div className="info-cell">
            <p className="Tx16M24 info-title "> موعد السفر</p>
            <p className="Tx18M32 info-detail ">{data?.travelDate ? convertUnixToDate(data.travelDate) : ""}</p>
          </div>
          {data?.status === "Processed" ? (
            <div className="info-cell">
              <p className="Tx16M24 info-title "> تم من قبل </p>
              <p className="Tx18M32 info-detail ">{data.processedBy}</p>
            </div>
          ) : null}
          {data?.processedDate ? (
            <div className="info-cell">
              <p className="Tx16M24 info-title "> تم بتاريخ </p>
              <p className="Tx18M32 info-detail ">
                {new Date(data?.processedDate * 1000).toLocaleString()}
              </p>
            </div>
          ) : null}
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {data?.status === "Notified" ? (
          <div
            className="reservationDetailsButtonsHolderColumn"
            style={{ width: "320px" }}
          >
            {data.informState ? (
              <div style={{ display: "flex", flexDirection: "column" }}>
                <button
                  className="button hollow dynamic-width-button"
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                    lineHeight: "0px",
                    marginBottom: "24px",
                    padding: "12px",
                    width: "100%",
                  }}
                  disabled={true}
                >
                  <div>
                    <WhatsappIcon></WhatsappIcon>
                  </div>
                  <p style={{ marginRight: "16px" }}>ارسال معلومات الحجز</p>
                </button>
                <InformStatus data={data.informState} />
              </div>
            ) : (
              <button
                className="button dynamic-width-button "
                disabled
                style={{
                  marginBottom: "24px",
                }}
              >
                <div style={{ display: "flex" }}>
                  <p className="Tx16M24">الحجز قيد الارسال</p>
                  <WhatsappMessageSending />
                </div>
              </button>
            )}

            <button
              className="button"
              style={{
                marginBottom: "24px",
              }}
              disabled={isSubmitting}
              onClick={(e) => {
                setIsSubmitting(true);
                ProcessReservation.processedReservation(
                  data?.reservationId
                ).then((e) => {
                  setCalendarOpen(false);
                  setSuccesResultOpen(false);
                  setIsSubmitting(false);
                });
              }}
            >
              تحديد كمكتمل
            </button>
          </div>
        ) : null}
        {data?.status === "Approved" ? (
          <div className="reservationDetailsButtonsHolderColumn">
            <div style={{ display: "flex", flexDirection: "column" }}>
              <button
                className="button hollow dynamic-width-button"
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                  lineHeight: "0px",
                  marginBottom: "24px",
                  padding: "12px",
                }}
                disabled={isSubmitting}
                onClick={async (e) => {
                  setIsSubmitting(true);
                  await ProcessReservation.InformReserverApproval(
                    data?.reservationId
                  ).then((e) => {
                    setIsSubmitting(false);
                    setCalendarOpen(false);
                    setSuccesResultOpen(false);
                  });
                }}
              >
                <div>
                  <WhatsappIcon></WhatsappIcon>
                </div>
                <p style={{ marginRight: "16px" }}>ارسال معلومات الحجز</p>
              </button>
              <DisclaimerComponent
                text="سيتم ارسال رسالة بمعلومات الحجز عبر
حساب الواتساب للرقم المذكور اعلاه"
              />
            </div>
            <div>
              <button
                className="button "
                style={{
                  marginBottom: "24px",
                }}
                disabled={isSubmitting}
                onClick={(e) => {
                  e.preventDefault();
                  setIsSubmitting(true);
                  ProcessReservation.processedReservation(
                    data?.reservationId
                  ).then((e) => {
                    setIsSubmitting(false);
                    setCalendarOpen(false);
                    setSuccesResultOpen(false);
                  });
                }}
              >
                تحديد الطلب كمكتمل
              </button>
              <DisclaimerComponent
                text={"بعد معالجة طلب العميل، يرجى تحديده كطلب مكتمل"}
              />
            </div>
          </div>
        ) : null}
        {data?.status === "Pending" ? (
          <div className="reservationDetailsButtonsHolder ">
            <button
              className="button approve"
              style={{ width: "244px" }}
              onClick={(e) => setCalendarOpen(true)}
            >
              قبول الطلب
            </button>
            <button
              className="button reject"
              disabled={isSubmitting}
              style={{ width: "244px" }}
              onClick={(e) => {
                setIsSubmitting(true);
                ProcessReservation.rejectReservation(data?.reservationId).then(
                  (e) => {
                    setIsSubmitting(false);
                  }
                );
              }}
            >
              رفض الطلب
            </button>
          </div>
        ) : null}
        <DashboardPopup
          isOpen={calendarOpen}
          onClose={() => setCalendarOpen(false)}
        >
          <div onClick={() => setCalendarOpen(false)}>
            <XIcon />
          </div>
          <p
            className="Tx20M32"
            style={{
              margin: "0px",
              textAlign: "center",
              paddingBottom: "16px",
              borderBottom: "1px solid #DEDFE0",
            }}
          >
            معلومات الحجز
          </p>
          <p className="Tx18M32" style={{ marginTop: "32px" }}>
            اختر اليوم المناسب لحجز موعد للزبون
          </p>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "24px",
              direction: "ltr",
            }}
          >
            <Calendar
              minDate={new Date()}
              tileDisabled={DisableWeekends}
              maxDetail="month"
              minDetail="month"
              onChange={(e) => onChange(Number(e?.valueOf()))}
            />
          </div>
          <button
            className="button"
            style={{ marginTop: "24px" }}
            disabled={isSubmitting}
            onClick={async (e) => {
              setIsSubmitting(true);
              reservationDate
                ? await ProcessReservation.approveReservation({
                    reservationId: data?.reservationId,
                    reservationDate: reservationDate,
                  }).then((e) => {
                    setIsSubmitting(false);
                    setCalendarOpen(false);
                    setSuccesResultOpen(true);
                  })
                : window.alert("please select a date");
              setIsSubmitting(false);
            }}
          >
            حجز
          </button>
        </DashboardPopup>
        <DashboardPopup
          isOpen={succesResultOpen}
          onClose={() => setSuccesResultOpen(false)}
        >
          <div onClick={() => setSuccesResultOpen(false)}>
            <XIcon />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginTop: "80px",
            }}
          >
            <SuccessIcon />
            <p
              className="Tx22M32"
              style={{ marginTop: "40px", textAlign: "center" }}
            >
              تمت عملية الحجز بنجاح
            </p>
          </div>
          <div
            className="reservationDetailsButtonsHolder"
            style={{ marginTop: "52px" }}
          >
            <button
              className="button whatsapp hollow"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              onClick={async (e) => {
                await ProcessReservation.InformReserverApproval(
                  data?.reservationId
                ).then((e) => {
                  setCalendarOpen(false);
                  setSuccesResultOpen(false);
                });
              }}
            >
              <div>
                <WhatsappIcon></WhatsappIcon>
              </div>
              <div style={{ marginRight: "8px" }}>ارسال معلومات الحجز</div>
            </button>
            <button
              className="button hollow"
              onClick={(e) => {
                setCalendarOpen(false);
                setSuccesResultOpen(false);
                onClose(false);
              }}
            >
              العودة الى الصفحة الرئيسية
            </button>
          </div>
        </DashboardPopup>
      </div>
    </DashboardPopup>
  );
};

import { Formik, Form } from "formik";
import {
  DisclaimerComponent,
  FailingIcon,
  Footer,
  Header,
  InputField,
  ReservationDate,
  ReservationDateComponent,
  SuccessIcon,
} from "../components";
import ReCAPTCHA from "react-google-recaptcha";
import { travelValidationSchema } from "./travelValidationSchema";
import { useAuth } from "../hooks/useAuth";
import { TravelFormValues } from "../types";
import usePostTravelFormValues from "../hooks/submitTravelFormData";
import axios, { AxiosError } from "axios";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

function isAxiosError(error: any): error is AxiosError {
  return error && error.isAxiosError;
}
export function Travelling() {
  const [submissionStatus, setSubmissionStatus] = useState<
    "idle" | "success" | "failure"
  >("idle");
  const [recaptcha, setRecaptcha] = useState<string | null>();
  const handleSubmit = async (values: TravelFormValues) => {
    if (!recaptcha) {
      window.alert("Recaptcha Failed");
      return;
    }

    try {
      const response = await post(values, recaptcha);

      if (isAxiosError(response)) {
        // Handle failure
        setSubmissionStatus("failure");
      } else {
        // Handle success
        setSubmissionStatus("success");
      }
    } catch (error) {
      // Handle failure in case of exception
      setSubmissionStatus("failure");
    }
  };

  const post = usePostTravelFormValues();
  switch (submissionStatus) {
    case "idle":
      return (
        <div className="screen">
          <div className="container">
            <Header />
            {/* <ReservationDateComponent /> */}
            <Formik
              initialValues={{
                fullName: "",
                familyName: "",
                phoneNumber: "",
                passportNumber: "",
                amount: "",
                travelDate: "",
              }}
              validationSchema={travelValidationSchema}
              onSubmit={async (values: TravelFormValues, { setSubmitting }) => {
                setSubmitting(true);
                // values.travelDate = (new Date(values.travelDate).getTime()/1000).toString()
                await handleSubmit(values);
              }}
            >
              {({ values, isValid, dirty, isSubmitting }) => (
                <Form>
                  <InputField label={"الاسم"} name={"fullName"}></InputField>
                  <InputField label={"اللقب"} name={"familyName"} />
                  <InputField label={"رقم الهاتف"} name={"phoneNumber"} />
                  <DisclaimerComponent text="يجب ان يحتوي رقم الهاتف على واتساب" />
                  <InputField
                    label={"رقم جواز السفر"}
                    name={"passportNumber"}
                  />
                  <InputField label={"المبلغ المطلوب"} name={"amount"} />
                  <DisclaimerComponent text="المبلغ بالدولار الامريكي" />
                  
                  <InputField label={"موعد السفر"} name={"travelDate"} type="date" />            

                  <ReCAPTCHA
                    sitekey="6LewXyApAAAAAA3vGJcqg8iRMFv73geEWxQ6mh_V"
                    onChange={setRecaptcha}
                    style={{ marginTop: "24px" }}
                  ></ReCAPTCHA>
                  <button
                    className="button"
                    type="submit"
                    disabled={!(isValid && dirty && !isSubmitting && recaptcha)}
                    style={{ marginTop: "48px" }}
                  >
                    التالي
                  </button>
                </Form>
              )}
            </Formik>
            <Footer />
          </div>
        </div>
      );

    case "failure":
      return <TravellingFormFailure />;
    case "success":
      return <TravellingFormSuccess />;
  }
}
const TravellingFormFailure = () => {
  const navigate = useNavigate();

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        marginTop: "200px",
      }}
    >
      <FailingIcon />
      <p className="Tx22M32" style={{ marginTop: "40px", textAlign: "center" }}>
        لقد حدث خطأ ما
      </p>
      <button
        className="button hollow"
        style={{ width: "244px" }}
        onClick={(e) => navigate("/reservation")}
      >
        العودة للرئيسية
      </button>
    </div>
  );
};
const TravellingFormSuccess = () => {
  const navigate = useNavigate();

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        marginTop: "200px",
      }}
    >
      <SuccessIcon />
      <p className="Tx22M32" style={{ marginTop: "40px", textAlign: "center" }}>
        تمت عملية الحجز بنجاح
      </p>
      <p className="Tx24M32" style={{ marginTop: "48px" }}>
        سوف يتم التواصل معك لتحديد وقت الموعد
      </p>
      <button
        className="button hollow"
        style={{ width: "244px" }}
        onClick={(e) => navigate("/reservation")}
      >
        العودة للرئيسية
      </button>
    </div>
  );
};

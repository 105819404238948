import { AxiosError } from "axios";
import api from "./Api";
import {
  ServerValidationResponse,
  SwitchFormValues,
  SwitchValuesAPIModel,
} from "../types";

const usePostSwitchFormValues = () => {
  const submitSwitchFormValues = async (
    data: SwitchFormValues,
    recaptchaToken: string
  ): Promise<AxiosError | ServerValidationResponse> => {
    let postingData: SwitchValuesAPIModel = {
      fullName: data.fullName,
      familyName: data.familyName,
      amount: {
        value: Number(data.amount),
        currency: "IQD",
      },
      phoneNumber: data.phoneNumber,
      reservationType: "Switch",
      recaptchaToken: recaptchaToken,
    };
    try {
      const response = await api.post("reserve", postingData);
      return response.data;
    } catch (error) {
      console.error("Error posting data:", error);
      return error as AxiosError;
    }
  };

  return submitSwitchFormValues;
};

export default usePostSwitchFormValues;

import * as Yup from "yup";

const phoneNumberRegex = /^(079|078|077|075|074)\d{8}$/;

export const switchValidationSchema = Yup.object({
  fullName: Yup.string()
    .matches(
      /^([\u0621-\u064A]+\s){2}[\u0621-\u064A]+$/,
      "الاسم الثلاثي باللغة العربية"
    )
    .max(100, "Must be 100 characters or less")
    .required("هذا الحقل مطلوب"),
  familyName: Yup.string()
    .required("هذا الحقل مطلوب")
    .matches(/^[\u0600-\u06FF\s]+$/, "اللقب باللغة العربية"),
  phoneNumber: Yup.string()
    .required("هذا الحقل مطلوب")
    .matches(phoneNumberRegex, "الرقم عراقي يبدأ بـ078 او 077 او 079 او 075")

    .matches(/^\d+$/, "الارقام باللغة الانجليزية فقط"),
  amount: Yup.string().required("هذا الحقل مطلوب"),
});

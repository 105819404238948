import { useState, useEffect, SetStateAction } from "react";
import { ReservationType, ReservationStatus, Footer } from "../components";
import { db } from "../firebase";
import { useAuth } from "../hooks/useAuth";
import { collection, onSnapshot } from "firebase/firestore";
import { Login } from "./login";
import { ReservationDocumentHit, ReservationDocumentType } from "../types";
import { ReservationDetails } from "./reservationDetails";
import { Navbar } from "./navbar";
import {
  InfiniteHits,
  InstantSearch,
  SearchBox,
  useInfiniteHits,
  useInstantSearch,
} from "react-instantsearch";
import algoliasearch from "algoliasearch/lite";
import type { Hit } from "instantsearch.js";
import { convertUnixToDate } from "../lib/dateConvert";


export function Admin() {
  const user = useAuth();
  const adminCheck = () => {
    user?.getIdToken();
  };
  useEffect(() => {
    adminCheck();
  });
  if (user === null) {
    return (
      <div className="App">
        <Login />
      </div>
    );
  }
  return <Portal />;
}

function ReservationHit({
  setDocumentId,
  setDetailsOpen,
}: {
  setDocumentId: React.Dispatch<React.SetStateAction<string | undefined>>;
  setDetailsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const {
    hits,
    currentPageHits,
    results,
    isFirstPage,
    isLastPage,
    showPrevious,
    showMore,
    sendEvent,
  } = useInfiniteHits();
  return (
    <>
      <table className="contacts-table">
        <thead>
          <tr>
            <th>حالة الطلب</th>
            <th>رقم الحجز</th>
            <th>الاسم</th>
            <th>اللقب</th>
            <th>رقم الهاتف</th>
            <th>الخدمة</th>
            <th>موعد السفر</th>
          </tr>
        </thead>
        <tbody>
          {hits.map((x) => (
            <tr
              key={x.objectID} // Assuming objectID is unique
              style={{ cursor: "pointer" }}
              onClick={() => {
                setDocumentId(undefined);
                setDocumentId(x.objectID);
                setDetailsOpen(true);
              }}
            >
              <td>
                <ReservationStatus
                  status={x.status ? x.status.toString() : "pending"}
                />
              </td>
              <td>{x.reservationId ? x.reservationId.toString() : "-"}</td>
              <td>{x.fullName ? x.fullName.toString() : "-"}</td>
              <td>{x.familyName ? x.familyName.toString() : "-"}</td>
              <td>{x.phoneNumebr ? x.phoneNumebr.toString() : "-"}</td>

              <td>
                {ReservationType(
                  x?.reservationType ? x?.reservationType.toString() : ""
                )}
              </td>
              <td>{x.travelDate ? convertUnixToDate(x.travelDate) : "-"}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <button onClick={showMore} disabled={isLastPage} className="button">
        عرض المزيد
      </button>
    </>
  );
}

function Portal() {
  const [documentId, setDocumentId] = useState<string | undefined>();
  const [detailsOpen, setDetailsOpen] = useState<boolean>(false);

  const searchClient = algoliasearch(
    "9DXL56SSD0",
    "648fcdb5af8b63f29315e5e85034a7dc"
  );

  return (
    <div
      style={{
        backgroundColor: "#FAFAFA",
        minHeight: "100vh",
        paddingBottom: "40px",
      }}
    >
      <Navbar />
      <div
        style={{
          height: "100%",
          padding: "24px 24px",
        }}
      >
        <InstantSearch indexName="ReservationData" searchClient={searchClient}>
          <SearchBox
            placeholder="ابحث بأستخدام الاسم او رقم الهاتف"
            classNames={{
              input: "input",
              submit: "hidden",
              reset: "hidden",
            }}
          />
          <ReservationHit
            setDetailsOpen={setDetailsOpen}
            setDocumentId={setDocumentId}
          />
        </InstantSearch>
      </div>
      <Footer />
      <ReservationDetails
        isOpen={detailsOpen}
        onClose={setDetailsOpen}
        id={documentId}
      />
    </div>
  );
}

import { Formik, Form } from "formik";
import {
  DisclaimerComponent,
  FailingIcon,
  Footer,
  Header,
  InputField,
  ReservationDate,
  ReservationDateComponent,
  SuccessIcon,
} from "../components";
import { switchValidationSchema } from "./switchValidationSchema";
import { SwitchFormValues } from "../types";
import usePostSwitchFormValues from "../hooks/submitSwitchFormData";
import { useAuth } from "../hooks/useAuth";
import ReCAPTCHA from "react-google-recaptcha";
import { useState } from "react";
import { AxiosError } from "axios";
import { useNavigate } from "react-router-dom";

function isAxiosError(error: any): error is AxiosError {
  return error && error.isAxiosError;
}

export function Switch() {
  const [recaptcha, setRecaptcha] = useState<string | null>();
  const [submissionStatus, setSubmissionStatus] = useState<
    "idle" | "success" | "failure"
  >("idle");
  const submitValues = usePostSwitchFormValues();

  const handleSubmit = async (values: SwitchFormValues) => {
    if (!recaptcha) {
      window.alert("Recaptcha Failed");
      return;
    }

    try {
      const response = await submitValues(values, recaptcha);

      if (isAxiosError(response)) {
        // Handle failure
        setSubmissionStatus("failure");
      } else {
        // Handle success
        setSubmissionStatus("success");
      }
    } catch (error) {
      // Handle failure in case of exception
      setSubmissionStatus("failure");
    }
  };

  switch (submissionStatus) {
    case "idle":
      return (
        <div className="screen">
          <div className="container">
            <Header />
            <Formik
              initialValues={{
                fullName: "",
                familyName: "",
                phoneNumber: "",
                amount: "",
              }}
              validationSchema={switchValidationSchema}
              onSubmit={async (values: SwitchFormValues, { setSubmitting }) => {
                setSubmitting(true);
                await handleSubmit(values);
                console.log("e");
              }}
            >
              {({ values, isValid, dirty, isSubmitting }) => (
                <Form>
                  <InputField label={"الاسم"} name={"fullName"}></InputField>
                  <InputField label={"اللقب"} name={"familyName"}></InputField>
                  <InputField label={"رقم الهاتف"} name={"phoneNumber"} />
                  <DisclaimerComponent text="يجب ان يحتوي رقم الهاتف على واتساب" />
                  <InputField label={"مبلغ التحويل"} name={"amount"} />
                  <DisclaimerComponent text="المبلغ بالدينار العراقي" />
                  <ReCAPTCHA
                    sitekey="6LewXyApAAAAAA3vGJcqg8iRMFv73geEWxQ6mh_V"
                    onChange={setRecaptcha}
                    style={{ marginTop: "24px" }}
                  ></ReCAPTCHA>
                  <button
                    className="button"
                    type="submit"
                    disabled={!(isValid && dirty && !isSubmitting && recaptcha)}
                    style={{ marginTop: "48px" }}
                  >
                    التالي
                  </button>
                </Form>
              )}
            </Formik>
            <Footer />
          </div>
        </div>
      );
    case "failure":
      return <SwitchFormFailure />;
    case "success":
      return <SwitchFormSuccess />;
  }
}

const SwitchFormSuccess = () => {
  const navigate = useNavigate();
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        marginTop: "200px",
      }}
    >
      <SuccessIcon />
      <p className="Tx22M32" style={{ marginTop: "40px", textAlign: "center" }}>
        تمت عملية الحجز بنجاح
      </p>
      <p className="Tx24M32" style={{ marginTop: "48px" }}>
        سوف يتم التواصل معك لتحديد وقت الموعد
      </p>

      <button
        className="button hollow"
        style={{ width: "244px" }}
        onClick={(e) => navigate("/reservation")}
      >
        العودة للرئيسية
      </button>
    </div>
  );
};
const SwitchFormFailure = () => {
  const navigate = useNavigate();

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        marginTop: "200px",
      }}
    >
      <FailingIcon />
      <p className="Tx22M32" style={{ marginTop: "40px", textAlign: "center" }}>
        لقد حدث خطأ ما
      </p>
      <button
        className="button hollow"
        style={{ width: "244px" }}
        onClick={(e) => navigate("/reservation")}
      >
        العودة للرئيسية
      </button>
    </div>
  );
};

import { signInWithEmailAndPassword } from "firebase/auth";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { useState } from "react";
import { auth } from "../firebase";
import * as Yup from "yup";

const validationSchema = Yup.object().shape({
  userName: Yup.string()
    .email("Invalid email")
    .matches(/\.[a-zA-Z]+$/, "Invalid email")
    .required("Email is required"),
  password: Yup.string().required("Password is required"),
});
export function Login() {
  const [error, setError] = useState<string>("");

  const onLogin = (values: { userName: string; password: string }) => {
    const { userName, password } = values;

    signInWithEmailAndPassword(auth, userName, password).catch((error) => {
      setError("Username or password is wrong");
    });
  };

  return (
    <div>
      <div className="screen">
        <div className="container" style={{ justifyContent: "center" }}>
          <p className="Tx22M32" style={{ textAlign: "center" }}>
            تسجيل دخول
          </p>
          <Formik
            initialValues={{ userName: "", password: "" }}
            validationSchema={validationSchema}
            onSubmit={(values) => {
              onLogin(values);
            }}
          >
            {({ isValid, handleBlur, dirty }) => (
              <Form>
                <div>
                  <div>
                    <label htmlFor="userName" className="Tx16M24">
                      اسم المستخدم
                    </label>
                    <br />
                    <Field
                      type="text"
                      id="userName"
                      name="userName"
                      onBlur={handleBlur}
                      className="input"
                      autoComplete="off"
                    />
                    <ErrorMessage
                      name="userName"
                      component="div"
                      className="error"
                    />
                  </div>
                </div>
                <div style={{ marginTop: "32px" }}>
                  <div>
                    <label htmlFor="password" className="Tx16M24">
                      كلمة السر
                    </label>
                    <br />
                    <Field
                      type="password"
                      id="password"
                      name="password"
                      className="input"
                      onBlur={handleBlur}
                      autoComplete="off"
                    />
                    <ErrorMessage
                      name="password"
                      component="div"
                      className="error"
                    />
                  </div>
                </div>
                <div style={{ marginTop: "32px" }}>
                  <button
                    type="submit"
                    className="button"
                    disabled={!isValid || !dirty}
                  >
                    تسجيل دخول
                  </button>
                </div>
                {error && <div className="error">{error}</div>}{" "}
                {/* Display the error message */}
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
}

import React from "react";
import {
  ContactSVG,
  EmailSVG,
  HeroSVG,
  PhoneIcon,
  SwitchSVG,
  TravelSVG,
} from "./heroSVG";
import { Link } from "react-router-dom";
import { CuneicodeLogo, Header } from "../components";

const HomePage = () => {
  return (
    <>
      <div style={{ marginBottom: "24px" }}>
        <Header></Header>
      </div>
      <div className="HomeContainer">
        <div className="Hero">
          <div className="heroTextSection">
            <p className="Tx34B48">الشركة العراقية للصرافة.</p>
            <p className="Tx34B48">
              للحصول على احدى خدماتنا يرجى الحجز عبر الاستمارة الالكترونية.
              للحجز اضغط على الزر
            </p>
            <Link to="/reservation">
              <button className="Herobutton">احجز موعد</button>
            </Link>
          </div>
          <HeroSVG></HeroSVG>
        </div>
        <div className="Services">
          <p className="Tx32B48" style={{ marginTop: "80px" }}>
            خدماتنا
          </p>
          <div className="servicesShowcase">
            <Link to={"/switch"}>
              <div className="servicecard">
                <SwitchSVG />
                <p className="Tx32M40" style={{ textAlign: "center" }}>
                  حجز موعد خدمة Switch
                </p>
              </div>
            </Link>
            <Link to={"/travel"}>
              <div className="servicecard">
                <TravelSVG />
                <p className="Tx32M40" style={{ textAlign: "center" }}>
                  حجز موعد خدمة السفر
                </p>
              </div>
            </Link>
          </div>
        </div>
        <div className="Contact">
          <p className="Tx32B48" style={{ marginTop: "80px" }}>
            اتصل بنا
          </p>
          <div className="ContactShowCase">
            <div className="contactDetails">
              <p className="Tx30R48">
                يمكنكم التواصل معنا على الرقم أدناه للمزيد من المعلومات أو
                الاستفسارات
              </p>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                  gap: "16px",
                  backgroundColor: "#F3F4F5",
                  borderRadius: "16px",
                  padding: "20px 24px",
                }}
              >
                <p className="Tx24M32" style={{ margin: "0px" }}>
                  info@aliraqia.co
                </p>
                <EmailSVG />
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                  gap: "16px",
                  backgroundColor: "#F3F4F5",
                  borderRadius: "16px",
                  padding: "20px 24px",
                  marginTop: "40px",
                }}
              >
                <p className="Tx24M32" style={{ margin: "0px" }}>
                  9647745009000+
                </p>
                <PhoneIcon />
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                  gap: "16px",
                  backgroundColor: "#F3F4F5",
                  borderRadius: "16px",
                  padding: "20px 24px",
                  marginTop: "40px",
                }}
              >
                <p className="Tx24M32" style={{ margin: "0px" }}>
                  9647845009000+
                </p>
                <PhoneIcon />
              </div>
            </div>
            <ContactSVG />
          </div>
        </div>
        <p className="Tx32B48" style={{ marginTop: "80px" }}>
          عنواننا
        </p>

        <div className="mapHolder" style={{ marginBottom: "80px" }}>
          <p className="Tx30R48">
            بغداد - شارع الصناعة مقابل الجامعة التكنلوجية
          </p>
          <iframe
            title="company location"
            src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d1667.185253830099!2d44.44525275670509!3d33.30912789333384!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMzPCsDE4JzMyLjkiTiA0NMKwMjYnNDcuNiJF!5e0!3m2!1sen!2siq!4v1699444318355!5m2!1sen!2siq"
            className="map"
            loading="lazy"
            style={{ border: "0px" }}
          ></iframe>
        </div>
      </div>
      <div style={{ backgroundColor: "#f3f4f5" }}>
        <div className="HomeFooter">
          <div
            className="gridItem"
            style={{ display: "flex", alignItems: "center" }}
          >
            <p
              className="Tx10SB16"
              style={{ textAlign: "center", marginRight: "8px" }}
            >
              Powered by
            </p>
            <CuneicodeLogo />
          </div>
          <div className="Tx18M32 gridItem">الشركة العراقية للصرافة</div>
        </div>
        <p style={{ textAlign: "end", fontSize: "10px", padding: "40px" }}>
          Our commitment to privacy and security is a core aspect of our
          service. This policy outlines the handling of information in our
          operations. Personal information necessary for our services is handled
          responsibly. This includes details provided during account
          registration, transactions, and other interactions. The purpose of
          handling this information includes processing transactions, offering
          customer service, and fulfilling legal obligations. Information may be
          shared with third parties under specific circumstances, such as
          compliance with laws or regulatory requirements. We prioritize the
          security of information. Our measures are designed to protect against
          unauthorized access and ensure data confidentiality. For international
          data transfers, we adhere to legal standards and ensure secure and
          lawful handling. Our services are not intended for individuals under
          18 years. We are committed to not engaging with underage individuals
          in compliance with relevant laws. Users have rights regarding their
          information, including accessing, correcting, and requesting deletion.
          These rights can be exercised through the contact avenues provided.
          Cookies and similar technologies are utilized to enhance user
          experience and service efficiency. Users have options regarding these
          technologies. We reserve the right to update this policy. Significant
          changes will be communicated effectively. For any inquiries or
          concerns regarding privacy, please reach out to us at info@aliraqia.co
          . This policy takes effect as of 2023.
        </p>
      </div>
    </>
  );
};

export default HomePage;

import axios, { AxiosInstance } from "axios";
import { auth } from "../firebase";
import apiDomain from "../apireference.json";

const getToken = async (): Promise<string | null> => {
  return new Promise((resolve, reject) => {
    const user = auth.currentUser;
    if (user) {
      user
        .getIdToken()
        .then((token) => {
          resolve(token);
        })
        .catch((error) => {
          reject(error);
        });
    } else {
      resolve(null);
    }
  });
};

const dev = apiDomain.apiDomain;
const api: AxiosInstance = axios.create({
  baseURL: `${dev}`,
});

api.interceptors.request.use(
  async (config: any) => {
    const token = await getToken();
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    config.headers["Cache-Control"] = "no-cache";
    config.headers["Pragma"] = "no-cache";
    config.headers["Expires"] = "0";
    return config;
  },
  (error: any) => {
    return Promise.reject(error);
  }
);

export default api;

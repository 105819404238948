import { AxiosError } from "axios";
import api from "./Api";
import { ServerValidationResponse } from "../types";
import { auth } from "../firebase";

const useProccessReservation = () => {
  const processedReservation = async (
    data: string | undefined
  ): Promise<AxiosError | ServerValidationResponse> => {
    let postingData: { reservationId: string | undefined } = {
      reservationId: data,
    };
    try {
      const response = await api.post("processReservation", postingData);
      return response.data;
    } catch (error) {
      console.error("Error posting data:", error);
      return error as AxiosError;
    }
  };
  const approveReservation = async ({
    reservationId,
    reservationDate,
  }: {
    reservationId: string | undefined;
    reservationDate: number;
  }): Promise<AxiosError | ServerValidationResponse> => {
    let postingData: {
      reservationId: string | undefined;
      reservationDate: number;
    } = {
      reservationId: reservationId,
      reservationDate: reservationDate,
    };
    console.log(postingData);
    try {
      const response = await api.post("approveReservation", postingData, {
        headers: {
          Authorization: `Bearer ${auth.currentUser?.getIdToken()}`,
        },
      });
      return response.data;
    } catch (error) {
      console.error("Error posting data:", error);
      return error as AxiosError;
    }
  };
  const rejectReservation = async (
    reservationId: string | undefined
  ): Promise<AxiosError | ServerValidationResponse> => {
    let postingData: { reservationId: string | undefined } = {
      reservationId: reservationId,
    };
    try {
      const response = await api.post("reject", postingData);
      return response.data;
    } catch (error) {
      console.error("Error posting data:", error);
      return error as AxiosError;
    }
  };
  const InformReserverApproval = async (
    reservationId: string | undefined
  ): Promise<AxiosError | ServerValidationResponse> => {
    let postingData: { reservationId: string | undefined } = {
      reservationId: reservationId,
    };
    try {
      const response = await api.post("informReserverApproval", postingData);
      return response.data;
    } catch (error) {
      console.error("Error posting data:", error);
      return error as AxiosError;
    }
  };

  return {
    processedReservation,
    approveReservation,
    rejectReservation,
    InformReserverApproval,
  };
};

export default useProccessReservation;

export function convertUnixToDate(unix: any){

    // Convert string to number and then to milliseconds
    const timestamp = Number(unix);
    const date = new Date(timestamp * 1000);
    
    // Extract day, month, and year
    const day = String(date.getUTCDate()).padStart(2, '0');
    const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // Months are zero-based
    const year = date.getUTCFullYear();
    
    // Format as dd/mm/yyyy
    const formattedDate = `${day}/${month}/${year}`;
    return formattedDate
  }
  
import { getAuth } from "@firebase/auth";
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyC3OIN02iW7yYGbPiC7rZYaimw4IKlO8kc",
  authDomain: "exss-iraqia.firebaseapp.com",
  projectId: "exss-iraqia",
  storageBucket: "exss-iraqia.appspot.com",
  messagingSenderId: "753732939988",
  appId: "1:753732939988:web:00b6dc8a4e61d6a3000b41",
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

const auth = getAuth(app);
export { app, auth, db };

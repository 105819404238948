import { AxiosError } from "axios";
import api from "./Api";
import {
  ServerValidationResponse,
  TravelFormValues,
  TravelValuesAPIModel,
} from "../types";

const usePostTravelFormValues = () => {
  const submitTravelFormValues = async (
    data: TravelFormValues,
    recaptchaToken: string
  ): Promise<AxiosError | ServerValidationResponse> => {
    let postingData: TravelValuesAPIModel = {
      fullName: data.fullName,
      familyName: data.familyName,
      passportNumber: data.passportNumber,
      phoneNumber: data.phoneNumber,
      amount: {
        value: Number(data.amount),
        currency: "USD",
      },
      reservationType: "Travel",
      travelDate: data.travelDate,
      recaptchaToken: recaptchaToken,
    };
    try {
      console.log(postingData);
      const response = await api.post("reserve", postingData);
      return response.data;
    } catch (error) {
      console.error("Error posting data:", error);
      return error as AxiosError;
    }
  };

  return submitTravelFormValues;
};

export default usePostTravelFormValues;

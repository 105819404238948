import * as Yup from "yup";
const phoneNumberRegex = /^(079|078|077|075|074)\d{8}$/;

export const travelValidationSchema = Yup.object({
  fullName: Yup.string()
    .matches(
      /^([\u0621-\u064A]+\s){2}[\u0621-\u064A]+$/,
      "الاسم الثلاثي باللغة العربية"
    )
    .max(100, "Must be 100 characters or less")
    .required("هذا الحقل مطلوب"),
  familyName: Yup.string()
    .required("هذا الحقل مطلوب")
    .matches(/^[\u0600-\u06FF\s]+$/, "اللقب باللغة العربية"),
  phoneNumber: Yup.string()
    .required("هذا الحقل مطلوب")
    .matches(phoneNumberRegex, "الرقم عراقي يبدأ بـ078 او 077 او 079 او 075")
    .matches(/^\d+$/, "الارقام باللغة الانجليزية فقط"),
  passportNumber: Yup.string()
    .required("هذا الحقل مطلوب")

    .matches(
      /^[a-zA-Z]{0,1}\d+$/,
      "يجب أن يبدأ الحقل بحرف على الأكثر تلاها أرقام فقط."
    )
    .min(8, "يجب أن يكون طول الحقل 8 أحرف على الأقل.")
    .max(9, "يجب أن يكون طول الحقل 9 أحرف على الأكثر."),
  amount: Yup.string().required("هذا الحقل مطلوب"),
  travelDate: Yup.date()
    .required('موعد السفر مطلوب')
    .typeError('تاريخ السفر غير صحيح') 
    .min(new Date(), 'تاريخ السفر لا يمكن أن يكون قبل اليوم')
});

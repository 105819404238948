import { signOut } from "@firebase/auth";
import { Formik, Form } from "formik";
import { useState } from "react";
import {
  EditIcon,
  SignOutIcon,
  DashboardPopup,
  XIcon,
  InputField,
} from "../components";
import { auth } from "../firebase";
import { useAuth } from "../hooks/useAuth";
import { Link } from "react-router-dom";

export const Navbar = () => {
  const [isBurgerOpen, setIsBurgerOpen] = useState<boolean>(false);
  const [travelPop, isTravelPop] = useState<boolean>(false);
  const [switchPop, isSwitchPop] = useState<boolean>(false);
  const user = useAuth();

  const handleLogOut = () => {
    signOut(auth).catch((error) => {
      console.log(error);
    });
  };
  return (
    <div className="navbar">
      <div style={{ display: "flex" }}>
        <button
          className="burgerButton"
          onClick={() => setIsBurgerOpen(!isBurgerOpen)}
        >
          <div
            style={{
              backgroundColor: "#E8ECF4",
              borderRadius: "50px",
              width: "32px",
              height: "32px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <p
              className="Tx16SB24"
              style={{
                fontSize: "18px",
                fontStyle: "normal",
                color: "black",
              }}
            >
              {typeof user?.email === "string"
                ? user.email[0].toUpperCase()
                : null}
            </p>
          </div>
        </button>
        <div>
          {isBurgerOpen ? (
            <div className="burgerMenu">
              <div
                style={{
                  padding: "8px 16px",
                }}
              >
                <p className="ManTx18SB24" style={{ textAlign: "start" }}>
                  {user?.displayName}
                </p>
                <p className="ManTx14R24" style={{ textAlign: "start" }}>
                  {user?.email}
                </p>
              </div>
              <div
                style={{
                  borderTop: "1px solid #DEDFE0",
                  borderBottom: "1px solid #DEDFE0",
                  padding: "8px",
                }}
              >
                <button
                  className="burgerMenuButton"
                  onClick={() => isTravelPop(true)}
                  style={{
                    padding: "12px 8px",
                  }}
                >
                  <EditIcon />
                  <p
                    className="Tx16M24 "
                    style={{
                      color: "#202427",
                      marginRight: "8px",
                    }}
                  >
                    تعديل اقصى حد للمسافرين
                  </p>
                </button>
                <p className="ManTx14R24">الحالي : 3000 $</p>
              </div>
              <div
                style={{
                  borderBottom: "1px solid #DEDFE0",
                  padding: "8px",
                }}
              >
                <button
                  className="burgerMenuButton"
                  onClick={() => isSwitchPop(true)}
                  style={{
                    padding: "12px 8px",
                  }}
                >
                  <EditIcon />
                  <p
                    className="Tx16M24 "
                    style={{
                      color: "#202427",
                      marginRight: "8px",
                    }}
                  >
                    تعديل اقصى حد Switch
                  </p>
                </button>
                <p className="ManTx14R24">الحالي : 3000 $</p>
              </div>

              <div style={{ padding: "12px 8px" }}>
                <button
                  className="burgerMenuButton"
                  onClick={handleLogOut}
                  style={{ padding: "12px 8px" }}
                >
                  <SignOutIcon />
                  <p
                    className="Tx16M24 "
                    style={{ color: "#202427", marginLeft: "8px" }}
                  >
                    تسجيل الخروج
                  </p>
                </button>
              </div>
            </div>
          ) : null}
        </div>
      </div>
      <Link to={"/"}>
        <p className="Tx14M24">الشركة العراقية للصرافة</p>
      </Link>

      <DashboardPopup
        isOpen={travelPop}
        onClose={() => isTravelPop(!travelPop)}
      >
        <div onClick={() => isTravelPop(!travelPop)}>
          <XIcon></XIcon>
        </div>
        <p className="Tx18M32 info-detail " style={{ textAlign: "center" }}>
          تحديث الحد الاقصى لخدمات السفر
        </p>
        <Formik
          initialValues={{ travllersLimit: "" }}
          onSubmit={(values) => {
            console.log(values);
          }}
        >
          {({ values, isValid, dirty, isSubmitting }) => (
            <Form>
              <InputField label={"الحد الاقصى"} name={"input"}></InputField>
              <button
                className="button"
                type="submit"
                disabled={!isValid || !dirty || isSubmitting}
                style={{ marginTop: "64px" }}
              >
                تحديث
              </button>
            </Form>
          )}
        </Formik>
      </DashboardPopup>
      <DashboardPopup
        isOpen={switchPop}
        onClose={() => isSwitchPop(!switchPop)}
      >
        <div onClick={() => isSwitchPop(!switchPop)}>
          <XIcon />
        </div>
        <p className="Tx18M32 info-detail " style={{ textAlign: "center" }}>
          تحديث الحد الاقصى لخدمات Switch
        </p>
        <Formik
          initialValues={{ switchLimit: "" }}
          onSubmit={(values) => {
            console.log(values);
          }}
        >
          {({ values, isValid, dirty, isSubmitting }) => (
            <Form>
              <InputField label={"الحد الاقصى"} name={"input"} />
              <button
                className="button"
                type="submit"
                disabled={!isValid || !dirty || isSubmitting}
                style={{ marginTop: "64px" }}
              >
                تحديث
              </button>
            </Form>
          )}
        </Formik>
      </DashboardPopup>
    </div>
  );
};

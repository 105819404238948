import React from "react";
import "./App.css";
import { Switch } from "./Switch/switchForm";
import { Footer, Header } from "./components";
import { BrowserRouter, Routes, Route, Link } from "react-router-dom";
import { Travelling } from "./Travel/travelingForm";
import { Admin } from "./Portal";
import HomePage from "./home";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/reservation" element={<Main />} />
        <Route path="/switch" element={<Switch />} />
        <Route path="/travel" element={<Travelling />} />
        <Route path="/portal" element={<Admin />} />
        <Route path="/*" element={<HomePage />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;

function Main() {
  return (
    <div className="screen">
      <div className="container">
        <Header />
        {/* <ReservationDateComponent /> */}
        <p className="Tx16M24" style={{ marginTop: "40px" }}>
          اختر نوع التقديم
        </p>
        <Link to="/switch">
          <div className="input" style={{ marginTop: "24px" }}>
            Switch
          </div>
        </Link>
        <Link to="/travel">
          <div className="input" style={{ marginTop: "16px" }}>
            مسافرون
          </div>
        </Link>
        <Footer></Footer>
      </div>
    </div>
  );
}
